import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const DragonCnPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Dragon | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/4_Dragon.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">DRAGON</h2>

                    <div className="content-section__text">
                        <p>歡迎來到模擬未來數碼魔幻的飛龍 - DRAGON。 TRIBE多媒體團隊創作時，以48支LED數碼光管及32套馬達的組合，垂吊於天花中，以每5分鐘的頻率擺動和轉換顏色，創建出意想不到和超現代感的視角效果，為觀眾眼球帶來既新鮮又刺激的視覺效果。  <br/><br/>請勿觸摸，此展品易碎。</p>
                    </div>


                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">藝術團隊 — TRIBE</h2>
                        <div className="content-section__text">
                            <p>TRIBE 是一個於 2017 年在聖彼得堡成立的視聽藝術團體。他們的作品包括沉浸式燈光和聲音裝置，並已在世界各地展出：紐約時代廣場屏幕；中國寧波的寧波展； 俄羅斯聖彼得堡的GAMMA 節；葉卡捷琳堡的 Pixels Fest；俄羅斯喀山的NUR 節。</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default DragonCnPage;
